import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { useAsset } from '../../../../queries';
import { LocalStorageKeys, setLocalStorageValue } from '../../../../utils';

export const Rooms = ({ rooms, setRooms, selectedRoom, setSelectedRoom }) => {
    const [name, setName] = useState('');
    const addRoom = () => {
        if (!name) return;
        const newRooms = [...rooms, { name }];
        setRooms(newRooms);
        setSelectedRoom(newRooms.length - 1);
        setName('');
        setLocalStorageValue(LocalStorageKeys.SILENT_AUCTION_SCORESHEET_ROOMS, newRooms);
    }

    const updateRoomName = (index, value) => {
        const roomsCopy = [...cloneDeep(rooms)];
        roomsCopy[index].name = value;
        setRooms(roomsCopy);
        setLocalStorageValue(LocalStorageKeys.SILENT_AUCTION_SCORESHEET_ROOMS, roomsCopy);
    }

    const removeRoom = (index) => {
        const roomsCopy = [...cloneDeep(rooms)];
        roomsCopy.splice(index, 1);
        setRooms(roomsCopy);
        setLocalStorageValue(LocalStorageKeys.SILENT_AUCTION_SCORESHEET_ROOMS, roomsCopy);
    }

    const duplicateRoom = (index) => {
        const roomsCopy = [...cloneDeep(rooms)];
        const roomToDuplicate = roomsCopy[index];
        const newRooms = [...roomsCopy, { ...roomToDuplicate, name: roomToDuplicate.name + ' (copy)' }];
        setRooms(newRooms);
        setSelectedRoom(newRooms.length - 1);
        setLocalStorageValue(LocalStorageKeys.SILENT_AUCTION_SCORESHEET_ROOMS, newRooms);
    }

    const { data: closeLogo } = useAsset({
        folder: 'logo',
        name: 'close.png',
    });

    const { data: duplicateLogo } = useAsset({
        folder: 'logo',
        name: 'duplicate.png',
    });

    const { data: plusLogo } = useAsset({
        folder: 'logo',
        name: 'plus.png',
    });

    return (
        <div className="rooms-container tab-section-container">
            <strong>Stored Games:</strong>
            {rooms.length ? (
                <ol className="room-list">
                    {rooms.map((room, index) => (
                        <li
                            key={index}
                            className='room'
                            onClick={() => setSelectedRoom(index)}
                        >
                            <span>{index + 1}.</span>
                            <input
                                id={'room-' + index}
                                className={`default-input existing-room ${index === selectedRoom ? 'selected-room' : ''}`}
                                type="text"
                                value={room.name}
                                onChange={(e) => updateRoomName(index, e.target.value)}
                                placeholder="Game Name"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        document.getElementById('room-' + index).blur();
                                        document.querySelector('.room-input').focus();
                                    }
                                }}
                            />
                            <img
                                src={duplicateLogo}
                                alt="duplicate"
                                className="duplicate-logo"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    duplicateRoom(index);
                                }}
                            />
                            <img
                                src={closeLogo}
                                alt="close"
                                className="close-logo"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeRoom(index)
                                }}
                            />
                        </li>
                    ))}
                </ol>
            ) : (
                <p className="no-rooms">No games are currently active</p>
            )}

            <div className="room new-room">
                <span>{rooms.length + 1}.</span>
                <input
                    autoComplete='off'
                    className="default-input room-input"
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Game Name'
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') addRoom();
                    }}
                />
                <img
                    src={plusLogo}
                    alt="plus"
                    className={`plus-logo ${name ? '' : 'disabled'}`}
                    onClick={() => {
                        if (!name) return;
                        addRoom();
                    }}
                />
            </div>
        </div>
    )
}