import React, { useState } from 'react';
import { useAsset } from '../../../../queries';

export const GameDetails = ({ room, setRoom }) => {
    const [playerName, setPlayerName] = useState('');
    const {
        name = '',
        players = [],
        items = [],
    } = room;

    const { data: closeLogo } = useAsset({
        folder: 'logo',
        name: 'close.png',
    });

    const { data: plusLogo } = useAsset({
        folder: 'logo',
        name: 'plus.png',
    });

    const setRoomData = (prop, value) => {
        const roomDataCopy = {
            name,
            players,
            items,
            [prop]: value,
        };
        setRoom(roomDataCopy);
    }

    const updatePlayer = (index, value) => {
        const playersCopy = [...players];
        playersCopy[index] = value;
        setRoomData('players', playersCopy);
    }

    const addPlayer = () => {
        if (playerName === '') return;
        const playersCopy = [...players, playerName];
        setRoomData('players', playersCopy);
        setPlayerName('');
    }

    const removePlayer = (index) => {
        const playersCopy = [...players];
        playersCopy.splice(index, 1);
        setRoomData('players', playersCopy);
    }

    return (
        <div className="game-details-container tab-section-container">
            <p className="game-name">
                <strong>Selected Game:</strong>
                <input
                    id="game-name-input"
                    className="default-input game-name-input"
                    type="text"
                    value={name}
                    onChange={(e) => setRoomData('name', e.target.value)}
                    placeholder="Game Name"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            document.querySelector('.game-name-input').blur();
                        }
                    }}
                />
            </p>

            <div className="game-players">
                <strong>Players:</strong>
                {players.length ? (
                    <ol className='players'>
                        {players?.map((player, index) => (
                            <li key={index} className="player">
                                <span>{index + 1}.</span>
                                <input
                                    id={'player-' + index}
                                    className="default-input existing-player"
                                    type="text"
                                    value={player}
                                    onChange={(e) => updatePlayer(index, e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            document.getElementById('player-' + index).blur();
                                            document.querySelector('.player-input').focus();
                                        }
                                    }}
                                    placeholder="Player Name"
                                />
                                <img src={closeLogo} alt="close" className="close-logo" onClick={() => removePlayer(index)} />
                            </li>
                        ))}
                    </ol>
                ) : (
                    <>
                        <p className='no-player'>No players have been added.</p>
                    </>
                )}
                <div className="player new-player">
                    <span>{players.length + 1}.</span>
                    <input
                        id="player-name-input"
                        className="default-input player-input"
                        type="text"
                        placeholder="New Player Name"
                        onChange={(e) => setPlayerName(e.target.value)}
                        value={playerName}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                addPlayer()
                            }
                        }}
                    />
                    <img
                        src={plusLogo}
                        alt="plus"
                        className={`plus-logo ${name ? '' : 'disabled'}`}
                        onClick={() => {
                            if (!name) return;
                            addPlayer();
                        }}
                    />
                </div>
            </div>
        </div>
    )
}