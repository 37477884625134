import React, { useState } from 'react';
import { cloneDeep } from 'lodash';
import { useTab } from '../../../components/Tab';
import { LocalStorageKeys, getLocalStorageValue, setLocalStorageValue } from '../../../../utils';
import { GameDetails, Rooms, ScoreSheet, tabData } from '.';

export const GenericScoreSheet = () => {
    const { selectedTab, setSelectedTab, getTabs } = useTab({ tabData });
    const [rooms, setRooms] = useState(getLocalStorageValue(LocalStorageKeys.GENERIC_SCORESHEET_ROOMS) ?? []);
    const [selectedRoom, setSelectedRoom] = useState(0);

    const setRoom = (roomData) => {
        const roomsCopy = [...cloneDeep(rooms)];
        roomsCopy[selectedRoom] = roomData;
        setRooms(roomsCopy);
        setLocalStorageValue(LocalStorageKeys.GENERIC_SCORESHEET_ROOMS, roomsCopy);
    }

    return (
        <>
            {getTabs()}
            <div className="generic-score-sheet">
                {selectedTab === 'rooms' && (
                    <Rooms
                        rooms={rooms}
                        setRooms={setRooms}
                        selectedRoom={selectedRoom}
                        setSelectedRoom={setSelectedRoom}
                    />
                )}
                {selectedTab === 'details' && (
                    <GameDetails
                        room={rooms[selectedRoom] || {}}
                        setRoom={setRoom}
                    />
                )}
                {selectedTab === 'score' && (
                    <ScoreSheet
                        room={rooms[selectedRoom] || {}}
                        setRoom={setRoom}
                        setSelectedTab={setSelectedTab}
                    />
                )}
            </div>
        </>
    )
}